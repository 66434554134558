import React from "react";
import { Container } from "react-bootstrap";
import ContactForm from "./ContactForm";

function Contact (){
    return(
        <Container fluid="md" className="about-section">
           <ContactForm /> 
        </Container>
    );
}

export default Contact;