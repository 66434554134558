import React from 'react';

const Social ={
 twitter : 'https://x.com/ali_nematolahi',
 instagram : "https://www.instagram.com/iAli.Nematolahi",
//  telegram : "",
 github : "https://github.com/maestro6321",
 linkedin : "https://www.linkedin.com/in/Ali-nematolahi/"
};

export default Social;